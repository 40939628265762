<template>
  <div class="index">
    <ul class="menu">
      <li @click="openMenu('account')">我的资料</li>
      <li @click="openMenu('accountTruthName')">实名认证</li>
      <li @click="openMenu('accountAddress')">我的地址</li>
      <li @click="openMenu('accountOrder')">我的订单</li>
      <li @click="openMenu('accountCart')">我的购物车</li>
      <li @click="openMenu('accountTuijian')">我的推荐</li>
    </ul>
    <div class="main">
      <div class="mainTitle">
        <span>订单详情</span>
      </div>
      <div class="orderNo">订单号：{{ info.orderId }}</div>
      <div class="orderGood">
        <div class="titleTips">{{ info.orderStatusMsg }}</div>
        <div class="expressCompany" v-if="info.deliveryName">
          快递公司：<span class="names">{{ info.deliveryName }}</span>
          运单号：{{ info.deliveryId }}
        </div>
        <div class="goodItem" v-for="item in goodItem" :key="item.attrId">
          <img :src="item.image" alt="" />
          <div class="goodItemInfo">
            <div>{{ item.storeName }}</div>
            <div>{{ `${item.price}元 X ${item.cartNum}` }}</div>
            <div>{{ item.sku }}</div>
            <el-button
              v-if="info.status == 3 &&judgeStatus==0"
              round
              size="small"
              type="danger"
              @click="commentOreder(item)"
              >去评价</el-button
            >
            <el-button v-if="info.status == 3 &&judgeStatus==1" round
              size="small"
              type="danger">已评价</el-button>
          </div>
        </div>
      </div>
      <div class="orderGood">
        <div class="title">收货信息</div>
        <div class="orderGoodInfo"><span>姓 名：</span>{{ info.realName }}</div>
        <div class="orderGoodInfo">
          <span>联系电话：</span>{{ info.userPhone }}
        </div>
        <div class="orderGoodInfo">
          <span>收货地址：</span>{{ info.userAddress }}
        </div>
      </div>
      <div class="orderGood">
        <div class="title">支付方式</div>
        <div>
          <span>支付方式：</span
          >{{ `${info.payTypeStr}(${info.isChannel == 6 ? "native" : "APP"})` }}
        </div>
      </div>
      <div class="express">
        <div class="orderPrice">
          商品总价：<span>{{ info.proTotalPrice }}元</span>
        </div>
        <div class="expressPrice">
          运费：<span>{{ info.payPostage }}元</span>
        </div>
        <div class="totalPay">
          实付金额：<span
            ><span class="totalPrice">{{ info.totalPrice }}</span></span
          >元
        </div>
      </div>
    </div>
    <!-- 评价订单 -->
    <el-dialog
      title="订单评价"
      :visible.sync="showComment"
      :close-on-click-modal="false"
      @close="canceComment"
      custom-class="dialog-minwidth"
      :modal-append-to-body="false"
      width="700px"
    >
      <div style="padding: 0 10px 40px">
        <div
          class="goodsItem"
          v-for="items in currentGoods.orderInfoList"
          :key="items.id"
        >
          <img :src="items.image" alt="" />
          <div class="images">
            <div class="imagesItem">
              <span>{{ items.storeName }}</span
              ><span>{{ items.price }}元</span>
            </div>
            <div class="imagesItem">
              <span>{{ items.sku }}</span
              ><span>X {{ items.cartNum }}</span>
            </div>
          </div>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="商品评数："
            prop="productScore"
            style="lineheight: 40px"
          >
            <el-rate v-model="ruleForm.productScore" :colors="colors"></el-rate>
          </el-form-item>
          <el-form-item label="服务评数：" prop="serviceScore">
            <el-rate v-model="ruleForm.serviceScore" :colors="colors"></el-rate>
          </el-form-item>
          <el-form-item label="商品评价：" prop="comment">
            <el-input type="textarea" v-model="ruleForm.comment"></el-input>
          </el-form-item>
          <el-form-item prop="avatar" label-width="0">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :http-request="aliyunUploadBank"
            >
              <div
                class="picsItems"
                v-for="(item, index) in ruleForm.pics"
                :key="index"
              >
                <img :src="item" class="picsItems" />
                <i
                  class="el-icon-delete-solid delItems"
                  @click.stop="deleteItem(index)"
                ></i>
              </div>
              <div class="picsItems picsItems1">
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="canceComment">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          :loading="isSaveLoading"
          @click="submitComment"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiOrderDetail } from "@/api/goods";
export default {
  name: "basePAge",
  data() {
    return {
      judgeStatus: 0,
      step: 1,
      orderId: "",
      info: {},
      //商品评价
      showComment: false,
      currentGoods: {},
      ruleForm: {
        productScore: 0,
        serviceScore: 0,
        pics: [],
        comment: "",
        unique: "",
      },
      rules: {
        productScore: [
          { required: true, message: "请选择评级", trigger: "change" },
        ],
        serviceScore: [
          { required: true, message: "请选择评级", trigger: "change" },
        ],
        comment: [
          { required: true, message: "请输入您的评价", trigger: "blur" },
          { min: 5, message: "至少输入5个字", trigger: "blur" },
        ],
      },
      isSaveLoading: false,
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      productId: "",
    };
  },
  computed: {
    goodItem() {
      if (this.info.orderInfoList) {
        return this.info.orderInfoList;
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.orderId = this.$route.query.id;
    this.judgeStatus = this.$route.query.judgeStatus
    this.onload(this.orderId);
  },
  methods: {
    aliyunUploadBank(params) {
      let ossData = new FormData();
      ossData.append("multipart", params.file);
      ossData.append("model", "user");
      ossData.append("pid", 7);
      this.https.post("/api/front/user/upload/image", ossData).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.ruleForm.pics.push(res.data.data.url);
        }
      });
    },
    deleteItem(index) {
      let arr = this.ruleForm.pics;
      arr.splice(index, 1);
      this.$set(this.ruleForm.pics, arr);
    },
    openMenu(index) {
      this.$router.push({ name: index });
      localStorage.setItem("accountIndex", "account");
    },
    onload(orderId) {
      apiOrderDetail({ orderId }).then((res) => {
        if (res.code == 200) {
          this.info = res.data;
        }
      });
    },
    //评价订单
    commentOreder(item) {
      this.showComment = true;
      this.currentGoods = item;
      this.productId = item.productId;
      this.ruleForm.unique = item.attrId;
    },
    canceComment() {
      this.showComment = false;
      this.$refs["ruleForm"].resetFields();
    },
    submitComment() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.isSaveLoading = true;
          let params = this.ruleForm;
          params.pics = params.pics.join(",");
          params.productId = this.productId;
          params.orderNo = this.orderId;
          this.https.post("/api/front/order/comment", params).then((res) => {
            this.isSaveLoading = false;
            if (res.data.code == 200) {
              this.$message.success("评论成功");
              this.showComment = false;
              this.$refs["ruleForm"].resetFields();
              this.onload(this.orderId);
            } else {
              this.$message.warning(res.data.message);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  .menu {
    width: 200px;
    background-color: #fff;
    li {
      line-height: 60px;
      border-bottom: 1px solid #ccc;
      font-size: 16px;
    }
  }
  .main {
    width: 980px;
    background-color: #fff;
    text-align: left;
    padding: 0 25px;
    .mainTitle {
      line-height: 60px;
      // border-bottom: 1px dashed blue;
      span {
        font-size: 22px;
      }
    }
    .orderNo {
      line-height: 50px;
      color: #333;
      border-bottom: 1px solid #eee;
    }
    .orderGood {
      padding: 15px 0;
      border-bottom: 1px solid #eee;
      min-height: 150px;
      .title {
        line-height: 50px;
        color: #666;
      }
      .titleTips {
        font-size: 14px;
        line-height: 26px;
        color: #999;
      }
      .expressCompany {
        line-height: 40px;
        font-size: 14px;
        color: #999;
        .names {
          color: orangered;
          display: inline-block;
          margin-right: 15px;
        }
      }
      .goodItem {
        padding: 15px;
        display: flex;
        align-items: center;
        img {
          width: 100px;
          height: 100px;
        }
        .goodItemInfo {
          width: calc(100% - 120px);
          display: flex;
          justify-content: space-around;
          font-size: 15px;
          color: #666;
          align-items: center;
        }
      }
      .orderGoodInfo {
        line-height: 30px;
        color: #999;
        span {
          color: #333;
          display: inline-block;
          width: 100px;
        }
      }
    }
    .express {
      text-align: right;
      padding: 15px 0;
      border-bottom: 1px solid #eee;
      min-height: 150px;
      font-size: 14px;
      .orderPrice,
      .expressPrice,
      .totalPay {
        line-height: 40px;
        text-align: right;
        color: #666;
        span {
          display: inline-block;
          width: 200px;
          color: orange;
        }
        .totalPrice {
          font-size: 22px;
        }
      }
    }
  }
}
::v-deep.el-upload {
  width: 100%;
  display: flex;
  align-items: center;
}
.el-rate {
  height: 40px;
  line-height: 40px;
  text-align: left;
}
.picsItems {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 15px;
  position: relative;
  img {
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 1px solid #f1f2f3;
  }
  .delItems {
    position: absolute;
    font-size: 18px;
    top: 3px;
    right: 3px;
  }
}
.picsItems1 {
  border: 1px solid #f1f2f3;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
</style>